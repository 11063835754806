import * as React from "react";

import Layout from "../components/layout"
import Seo from "../components/seo";

import PropertySearchBar from '../components/property-search-bar/property-search-bar';
import PropertyCallout from "../components/property-callout/property-callout";
import PropertyPaging from "../components/property-paging/property-paging";
import PropertyFilter from "../components/property-filter/property-filter";
import PropertyItem from "../components/property-item/property-item";

const PropertySearch = () => (
  <Layout>
    <Seo title="Property Search" />
    <PropertySearchBar />
    <PropertyFilter />
    <PropertyItem 
      price="£400,000"
      location="Larch End, Minster On Sea, Sheerness,  WT12"
      description="LambornHill are pleased to offer for sale this large family home in a popular area of Minster. Ideal for the growing family is this well presented detached home which is full of space..."
      bedroomsCount="5"
      bathroomsCount="3"
      receptionsCount="3"
    />
    <PropertyItem 
      price="£400,000"
      location="Larch End, Minster On Sea, Sheerness,  WT12"
      description="LambornHill are pleased to offer for sale this large family home in a popular area of Minster. Ideal for the growing family is this well presented detached home which is full of space..."
      bedroomsCount="5"
      bathroomsCount="3"
      receptionsCount="3"
    />
    <PropertyCallout />
    <PropertyItem 
      price="£400,000"
      location="Larch End, Minster On Sea, Sheerness,  WT12"
      description="LambornHill are pleased to offer for sale this large family home in a popular area of Minster. Ideal for the growing family is this well presented detached home which is full of space..."
      bedroomsCount="5"
      bathroomsCount="3"
      receptionsCount="3"
    />
    <PropertyItem 
      price="£400,000"
      location="Larch End, Minster On Sea, Sheerness,  WT12"
      description="LambornHill are pleased to offer for sale this large family home in a popular area of Minster. Ideal for the growing family is this well presented detached home which is full of space..."
      bedroomsCount="5"
      bathroomsCount="3"
      receptionsCount="3"
    />
    <PropertyPaging />

  </Layout>
)

export default PropertySearch;
